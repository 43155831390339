import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`During 2017 we visited a lot of destinations with our little green campervan.
Here are our favourite ten locations. A great destination makes first of all
the singletracks and the nature. That is what we love, no matter if you have
to earn the descents yourself or have shuttle or lift support. Of course there
have to be enough trails to ride a couple of days and a good touristic
infrastructure is a bonus. We are not looking for typical bike parks.
The list has no rated sequence.`}</p>
    <h2>{`1 Zermatt, Switzerland`}</h2>
    <h4>{`Riding in a postcard`}</h4>
    <Image src="destinations/zermatt_4.jpg" mdxType="Image" />
    <p>{`Zermatt in Switzerland is surrounded by the high mountains of the main ridge
of the alps. One of the most famous ones, the Matterhorn, always in sight.
`}<a parentName="p" {...{
        "href": "/destination/switzerland/zermatt"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`2 Benasque, Spain`}</h2>
    <h4>{`Gem in the Pyrenees`}</h4>
    <Image src="destinations/benasque_3.jpg" mdxType="Image" />
    <p>{`Benasque - in the north of Spain - is the starting point for many hikers to
climb the 3404m high Pico de Aneto, the highest peak of the Pyrenees.
`}<a parentName="p" {...{
        "href": "/destination/spain/benasque"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`3 Reschen, Italy`}</h2>
    <h4>{`Enduro dreamland`}</h4>
    <Image src="destinations/reschen_1.jpg" mdxType="Image" />
    <p>{`The resort at the border of Austria and Italy is truly a gem for all
enduro fans. The lifts open up a wide area around the 1500m high
Reschenpass with it’s two beautiful lakes.
`}<a parentName="p" {...{
        "href": "/destination/italy/reschen"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`4 Canazei, Italy`}</h2>
    <h4>{`Endless trails in the Dolomites`}</h4>
    <Image src="destinations/canazei_1.jpg" mdxType="Image" />
    <p>{`The Dolomites in Italy are famous for their unique mountains. High vertical
rock walls merge into mellow hills covered in lush meadows.
`}<a parentName="p" {...{
        "href": "/destination/italy/canazei"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`5 Soca Valley, Slovenia`}</h2>
    <h4>{`A fairy-tale of elves and trails`}</h4>
    <Image src="destinations/bovec1.jpg" mdxType="Image" />
    <p>{`In the north-west of Slovenia the Soca river rises in the high mountains
behind Bovec. The crystal clear water, cataracts and canyons are world famous.
`}<a parentName="p" {...{
        "href": "/destination/slovenia/socavalley"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`6 Aínsa, Spain`}</h2>
    <h4>{`Trail paradise at the foothills of the Pyrenees`}</h4>
    <Image src="destinations/ainsa_3.jpg" mdxType="Image" />
    <p>{`Aínsa is one of the most popular mtb destination in Spain. The Zona Zero
is well known for its enduro tracks since the EWS took place in 2015.
`}<a parentName="p" {...{
        "href": "/destination/spain/ainsa"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`7 Aosta, Italy`}</h2>
    <h4>{`Big mountains`}</h4>
    <Image src="destinations/aosta_7.jpg" mdxType="Image" />
    <p>{`To speak of one destination is a bit undersized. The over 80 km long valley
offers endless possibilities and ends at the highest mountain of the alps,
the Mont Blanc.
`}<a parentName="p" {...{
        "href": "/destination/italy/aosta"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`8 St Moritz, Switzerland`}</h2>
    <h4>{`Top of the world`}</h4>
    <Image src="destinations/stmoritz_8.jpg" mdxType="Image" />
    <p>{`Not another boring Flow Trail! Yes yes, we know. But the Flow Trails in
St. Moritz are different! Really built well, they have an awesome rhythm
and get more fun the more you ride and the faster you get.
`}<a parentName="p" {...{
        "href": "/destination/switzerland/stmoritz"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`9 Aletsch, Switzerland`}</h2>
    <h4>{`Top trails next to the biggest glacier of the Alps`}</h4>
    <Image src="destinations/aletsch_5.jpg" mdxType="Image" />
    <p>{`The Aletsch glacier with over 20 km length is the biggest of the Alps. It
has its origin high up at famous peaks like the Mönch and Jungfrau.
`}<a parentName="p" {...{
        "href": "/destination/switzerland/aletsch"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`10 Freiburg, Germany`}</h2>
    <h4>{`A city full of bike-crazy people`}</h4>
    <Image src="destinations/freiburg2.jpg" mdxType="Image" />
    <p>{`A great place to ride in the low mountain ranges of Germany? Yes! The Black
Forest has more to offer than you think. Long descents up to 1000vm and a
huge density of singletrails right next to the city.
`}<a parentName="p" {...{
        "href": "/destination/germany/freiburg"
      }}>{`read more...`}</a></p>
    <br />
    <Image src="gunther/gunther-riding.jpg" mdxType="Image" />
    <p>{`This year we will be on tour again. Follow us on
`}<a parentName="p" {...{
        "href": "https://www.facebook.com/trailguide.net"
      }}>{`Facebook`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://www.instagram.com/trailguideapp"
      }}>{`Instagram`}</a>{`
to see the best pictures, singletracks and destinations.
On `}<a parentName="p" {...{
        "href": "https://trailguide.net"
      }}>{`trailguide.net`}</a>{` you will find the latest tracks
from us and the
`}<a parentName="p" {...{
        "href": "https://www.facebook.com/groups/184610292286074"
      }}>{`Trailguide community`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      